import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import styles from "./NavBar.module.css";
import AppBar from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import { Grid, Stack } from "@mui/material";
import PhoneEnabledIcon from "@mui/icons-material/PhoneEnabled";
import DraftsIcon from "@mui/icons-material/Drafts";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import logo from "../image/One Click Logo Blanco.png";
import YouTubeIcon from "@mui/icons-material/YouTube";


const NavBar = () => {
  const navigate = useNavigate();
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [log, setLog] = useState(true);
  const [scroll, setScroll] = useState(0);

  window.onscroll = function () {
    setScroll(window.scrollY);
  };

  useEffect(() => {
    if (scroll >= 100) {
      setLog(false);
    }
    if (scroll == 0) {
      setLog(true);
    }
  }, [scroll, log]);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(false);
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <>
      <AppBar
        id="appBar"
        sx={{
          position: "fixed",
          display: { xs: "none", md: "inline-block" },
          backgroundColor: log ? "transparent" : "rgba(0, 0, 0, 0.6)",
          border: "none",
          boxShadow: "none",
        }}
      >
        <Container maxWidth="xxl">
          <Toolbar disableGutters>
            <Grid container direction="row" justifyContent="space-between" alignItems="center">
              <Grid item>
                <Stack direction="row" spacing={2} sx={{ ml: "2vw" }}>
                  <PhoneEnabledIcon color="primary" />
                  <Typography variant="subtitle2" color="primary">
                    Contactate: 099252339 - 29155020
                  </Typography>
                  <DraftsIcon color="primary" />
                  <Typography variant="subtitle2" color="primary">
                    ventas@oneclick-apps.com
                  </Typography>
                </Stack>
              </Grid>
              <Grid item>
                <Stack direction="row" spacing={2} sx={{ mr: "2vw" }}>
                  {/* <FacebookIcon color="primary" sx={{ "&:hover": { color: "#3b5998" } }} /> */}

                  <InstagramIcon color="primary" sx={{ "&:hover": { color: "#d30050" } }} />

                  <YouTubeIcon color="primary" sx={{ "&:hover": { color: "#ff0000" } }} />

                  <LinkedInIcon color="primary" sx={{ "&:hover": { color: "#0a66c2" } }} />
                </Stack>
              </Grid>
            </Grid>
          </Toolbar>
        </Container>
      </AppBar>
      <AppBar
        id="appBar2"
        sx={{
          mt: { xs: 0, md: "64px" },
          position: "fixed",
          backgroundColor: log ? "transparent" : "rgba(0, 0, 0, 0.6)",
          border: "none",
          boxShadow: "none",
        }}
      >
        <Container maxWidth="xxl">
          <Toolbar disableGutters>
            <Grid container spacing={2} columns={16}>
              <Grid item xs={5} direction="row" justifyContent="center" alignItems="center">
                <Box
                  onClick={() => navigate("/")}
                  component="img"
                  src={logo}
                  alt="Logo"
                  sx={{
                    width: { xs: "100%", md: "40%" },
                    marginLeft: "4vh",
                    marginTop: "1.7vh",
                    pb: 2,
                    cursor: "pointer",
                  }}
                />
              </Grid>

              <Grid item sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
                <Grid container direction="row" justifyContent="flex-end" alignItems="center">
                  <div
                    onMouseEnter={handleOpenUserMenu}
                    onMouseLeave={handleCloseUserMenu}
                    style={{ position: "relative" }}
                  >
                    <Button sx={{ my: 2, color: "white", display: "block", mr: 2 }}>
                      Aplicaciones
                    </Button>
                    <Menu
                      color="black"
                      sx={{ mt: "45px", overflow: "hidden" }}
                      id="menu-appbar"
                      anchorEl={anchorElUser}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      keepMounted
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      open={Boolean(anchorElUser)}
                      onClose={handleCloseUserMenu}
                      onMouseLeave={handleCloseUserMenu}
                    >
                      <Link to="/aval-one-click" className={`${styles.link}`}>
                        <Button
                          onClick={handleCloseUserMenu}
                          className={`${styles.linkMenu}`}
                          sx={{
                            my: 2,
                            color: "black",
                            display: "block",
                            px: 3,
                            mr: 2,
                          }}
                        >
                          AVAL One Click
                        </Button>
                      </Link>
                      <Link to="/sgr-one-click" className={`${styles.link}`}>
                        <Button
                          onClick={handleCloseUserMenu}
                          className={`${styles.linkMenu}`}
                          sx={{
                            my: 2,
                            color: "black",
                            display: "block",
                            px: 3,
                            mr: 2,
                          }}
                        >
                          SGR One Click
                        </Button>
                      </Link>
                    </Menu>
                  </div>
                  <Button
                    onClick={() => navigate("/quienes-somos")}
                    sx={{ my: 2, color: "white", display: "block", mr: 2 }}
                  >
                    Quienes Somos
                  </Button>
                  <Button onClick={() => navigate("/contacto")} sx={{ my: 2, color: "white", display: "block", mr: 2 }}>
                    Contacto
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="primary"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: "block", md: "none" },
                }}
              >
                <Link to="/aval-one-click" className={`${styles.link}`}>   
                  <Button
                    onClick={handleCloseNavMenu}                    
                    className={`${styles.linkMenu}`}
                    sx={{
                      my: 2,
                      color: "black",
                      display: "block",
                      px: 3,
                      mr: 2,
                    }}
                  >
                    AVAL One Click
                  </Button>
                </Link>
                <Link to="/sgr-one-click" className={`${styles.link}`}>
                  <Button
                    onClick={handleCloseNavMenu}
                    className={`${styles.linkMenu}`}
                    sx={{
                      my: 2,
                      color: "black",
                      display: "block",
                      px: 3,
                      mr: 2,
                    }}
                  >
                    SGR One Click
                  </Button>
                </Link>
                <Link to="quienes-somos" style={{ "text-decoration": "none" }}>
                  <Button
                    onClick={handleCloseNavMenu}
                    sx={{
                      my: 2,
                      color: "black",
                      display: "block",
                      px: 3,
                      mr: 2,
                    }}
                  >
                    Quienes Somos
                  </Button>
                </Link>
                <Link to="contacto" style={{ "text-decoration": "none" }}>
                  <Button
                    onClick={handleCloseNavMenu}
                    sx={{
                      my: 2,
                      color: "black",
                      display: "block",
                      px: 3,
                      mr: 2,
                    }}
                  >
                    Contacto
                  </Button>
                </Link>
              </Menu>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </>
  );
};
export default NavBar;


